import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RankingComponent } from './ranking.component';
import { SeasonResultsComponent } from './season-results/season-results.component';
import { RaceResultsComponent } from './race-results/race-results.component';
import { RacesComponent } from './races/races.component';

const rankingRoutes: Routes = [
  {path: 'ranking', component: RankingComponent},
  {path: 'ranking/races', component: RacesComponent},
  {path: 'ranking/season-results', component: SeasonResultsComponent},
  {path: 'ranking/race-results/:raceId', component: RaceResultsComponent}
];

@NgModule({
  imports: [RouterModule.forChild(rankingRoutes)],
  exports: [RouterModule]
})
export class RankingRoutingModule {
}
