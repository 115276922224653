import { Pipe, PipeTransform } from '@angular/core';
import { TimeGap } from './time-gap';

@Pipe({
  name: 'hoursInterval'
})
export class TimeGapHoursPipe implements PipeTransform {

  protected timeGap: TimeGap;
  protected invertedTimeGap: TimeGap;

  constructor() {
    this.timeGap = new TimeGap({
      autoStart: false
    });
    this.invertedTimeGap = new TimeGap({
      autoStart: false,
      inverted: true
    });
  }

  transform(value: any, args?: string): string {
    if (args === 'inverted') {
      return this.invertedTimeGap.set(value).format();
    } else {
      return this.timeGap.set(value).format();
    }
  }
}
