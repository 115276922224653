import { Pipe, PipeTransform } from '@angular/core';
import { Result } from '../result/result';
import { ResultsConfig } from './results-config';
import { LaptimeRangeFilterPipe } from '../result/laptime-range-filter.pipe';

@Pipe({
  name: 'resultsFilter'
})
export class ResultsFilterPipe implements PipeTransform {

  constructor(private laptimeRangeFilterPipe: LaptimeRangeFilterPipe) {}

  transform(results: Result[], resultsConfig: ResultsConfig): Result[] {
    return results.filter(result => {
      if (result.verification === null) {
        return this.laptimeRangeFilterPipe.transform(result.microtime, resultsConfig);
      } else {
        return result.verification;
      }
    });
  }
}
