import { Injectable } from '@angular/core';
import { CsvParserService } from '../csv-parser.service';
import { GateReading } from './gate-reading';
import {
  distinctUntilChanged,
  filter,
  mergeMap,
  groupBy,
  map, pairwise,
  scan
} from 'rxjs/operators';
import { Observable, OperatorFunction } from 'rxjs';
import { MocksService } from '../../app-commons/mocks/mocks.service';
import { ResultsConfig } from '../../ranking/results/results-config/results-config';
import { StandingsConfig } from '../../ranking/standings/standings-config';
import { Result } from '../../ranking/results/result/result';
import { ResultsService } from '../../ranking/results/results.service';
import { ResultsFilterPipe } from '../../ranking/results/results-config/results-filter.pipe';
import { Driver } from '../drivers/driver';
import { NormalizeLocalISOStringPipe } from '../../app-commons/pipes/normalize-timestamp.pipe';
import { TagReading } from '../tag-readings/tag-reading';
import { ToUniqueTagReadingIdPipe } from '../tag-readings/to-unique-tag-reading-id.pipe';
import { ToUniqueResultIdPipe } from '../../ranking/results/result/to-unique-result-id.pipe';
import { DriversService } from '../drivers/drivers.service';

@Injectable({
  providedIn: 'root'
})
export class GateReadingsService {

  constructor(
    private csvParserService: CsvParserService,
    private mocks: MocksService,
    private resultsFilterPipe: ResultsFilterPipe,
    private readonly driversService: DriversService,
    private normalizeILocalSOStringPipe: NormalizeLocalISOStringPipe,
    private readonly toUniqueTagReadingIdPipe: ToUniqueTagReadingIdPipe,
    private readonly toUniqueResultIdPipe: ToUniqueResultIdPipe
  ) {
  }

  static merge(a: GateReading[], b: GateReading[]): GateReading[] {
    return GateReadingsService.findDuplicates(GateReadingsService.concat(a, b))[0];
  }

  static concat(...gateReadingsCollections: GateReading[][]): GateReading[] {
    let merged: GateReading[] = [];
    gateReadingsCollections.forEach((gateReadings: GateReading[]) => {
      merged = merged.concat(gateReadings);
    });
    return GateReadingsService.sort(merged);
  }

  static sort(gateReadings: GateReading[]): GateReading[] {
    return gateReadings.sort((a, b) => {
      if (a.first_detection_date > b.first_detection_date) {
        return 1;
      } else if (a.first_detection_date < b.first_detection_date) {
        return -1;
      } else {
        return a.gr_id - b.gr_id;
      }
    });
  }

  static findDuplicates(gateReadings: GateReading[]): [GateReading[], GateReading[]] {
    const uniques = {};
    const duplicates = [];
    gateReadings.forEach(gateReading => {
      if (gateReading.gr_id in uniques) {
        duplicates.push(gateReading);
      } else {
        uniques[gateReading.gr_id] = gateReading;
      }
    });
    return [
      Object.values(uniques),
      duplicates
    ];
  }

  filterThresholdOperator(): OperatorFunction<[GateReading, number], GateReading> {
    return (stream: Observable<[GateReading, number]>): Observable<GateReading> => {
      let threshold;
      return stream.pipe(
        map(([gateReading, thresholdSeconds]: [GateReading, number]) => {
          threshold = (thresholdSeconds || 2) * 1000 * 1000;
          return [gateReading, this.driversService.findByEpc(gateReading.tag_code)?.startNumber];
        }),
        groupBy(([gateReading, startNumber]: [GateReading, number]) => startNumber || gateReading),
        mergeMap(group => group.pipe(
          map(([gateReading, startNumber]) => gateReading),
          scan((previousLapStart: GateReading, gateReading: GateReading) => {
            const diff = (new Date(gateReading.first_detection_date).getTime() - new Date(previousLapStart.first_detection_date).getTime()) * 1000;
            if (diff >= threshold) {
              return gateReading;
            } else {
              return previousLapStart;
            }
          }),
          distinctUntilChanged((a, b) => a.gr_id === b.gr_id)
        ))
      );
    };
  }

  filterLaptimeDurationOperator(): OperatorFunction<[GateReading, number, number], GateReading> {
    return (stream: Observable<[GateReading, number, number]>): Observable<GateReading> => {
      let minimumLapDuration;
      let maximumLapDuration;
      return stream.pipe(
        map(([gateReading, minimumLapSeconds, maximumLapSeconds]: [GateReading, number, number]) => {
          minimumLapDuration = (minimumLapSeconds || 30) * 1000 * 1000;
          maximumLapDuration = (maximumLapSeconds || 360) * 1000 * 1000;
          return [gateReading, this.driversService.findByEpc(gateReading.tag_code)?.startNumber];
        }),
        groupBy(([gateReading, startNumber]: [GateReading, number]) => startNumber || gateReading),
        mergeMap(group => group.pipe(
          map(([gateReading, startNumber]) => gateReading),
          scan((lastLapStart: GateReading, gateReading: GateReading) => {
            const diff = (new Date(gateReading.first_detection_date).getTime() - new Date(lastLapStart.first_detection_date).getTime()) * 1000;
            if (diff >= minimumLapDuration && diff <= maximumLapDuration) {
              return gateReading;
            } else {
              return lastLapStart;
            }
          }),
          distinctUntilChanged((a, b) => a.gr_id === b.gr_id)
        ))
      );
    }
  }

  mapToResultsOperator(): OperatorFunction<[GateReading, Driver[], StandingsConfig, ResultsConfig], Result> {
    return (stream: Observable<[GateReading, Driver[], StandingsConfig, ResultsConfig]>): Observable<Result> => {
      let standingsConfig: StandingsConfig;
      let resultsConfig: ResultsConfig;
      let drivers: Driver[] = [];
      return stream.pipe(
        map(([gateReading, newDrivers, newStandingsConfig, newResultsConfig]: [GateReading, Driver[], StandingsConfig, ResultsConfig]) => {
          standingsConfig = newStandingsConfig;
          resultsConfig = newResultsConfig;
          drivers = newDrivers;
          return [gateReading, drivers.find(driver => driver.epcs.includes(gateReading.tag_code))?.startNumber];
        }),
        groupBy(([gateReading, startNumber]: [GateReading, number]) => startNumber || gateReading),
        mergeMap(group => group.pipe(
          map(([gateReading, startNumber]) => gateReading),
          pairwise(),
          map(([start, finish]: [GateReading, GateReading]): Result | null => {
            const driver = drivers.find(driver => driver.epcs.includes(finish.tag_code));
            if (driver) {
              const startTimestamp = new Date(this.normalizeILocalSOStringPipe.transform(start.first_detection_date)).getTime();
              const finishTimestamp = new Date(this.normalizeILocalSOStringPipe.transform(finish.first_detection_date)).getTime();
              const diff = (finishTimestamp - startTimestamp) * 1000;
              return {
                _id: this.toUniqueResultIdPipe.transform(finishTimestamp, finish.tag_code),
                driverId: driver.startNumber,
                startEpc: start.tag_code,
                finishEpc: finish.tag_code,
                microtime: diff,
                createdAt: finishTimestamp,
                correction: 0,
                verification: null
              };
            } else {
              return null;
            }
          }),
          filter((result: Result) => result !== null && this.resultsFilterPipe.transform([result], resultsConfig).length > 0),
          distinctUntilChanged(ResultsService.isSameId)
        ))
      );
    }
  }

  requestGateReadings(mockPath: string): Observable<GateReading[]> {
    return this.mocks.get(mockPath).pipe(
      map(csvContent => this.parseToGateReadings(csvContent))
    );
  }

  parseToGateReadings(csvContent: string): GateReading[] {
    return this.csvParserService.parse(csvContent).map(row => ({
        gr_id: +row[0],
        gate_id: +row[1],
        tag_code: row[2].replace(/\s+/g, ''),
        first_detection_date: this.normalizeILocalSOStringPipe.transform(row[3]),
        last_detection_date: this.normalizeILocalSOStringPipe.transform(row[4]),
        save_date: this.normalizeILocalSOStringPipe.transform((row[5])),
        status: +row[6],
        lap: row[7] === 'NULL' ? null : +row[7]
      } as GateReading)
    );
  }

  parseToTagReadings(gateReadingsCsvContent: string): TagReading[] {
    return this.csvParserService.parse(gateReadingsCsvContent).map(row => {
      const tagCode = row[2].replace(/\s+/g, '');
      const timestamp = new Date(this.normalizeILocalSOStringPipe.transform(row[3])).getTime();
      return {
        _id: this.toUniqueTagReadingIdPipe.transform(timestamp, tagCode),
        tagCode,
        timestamp
      };
    });
  }
}
