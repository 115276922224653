import { Component, EventEmitter, Output } from '@angular/core';
import { Driver } from './driver';
import { DriversService } from './drivers.service';

@Component({
  selector: 'mc-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.scss']
})
export class DriversComponent {

  @Output() driversChanged = new EventEmitter<Driver[]>();

  constructor(
    private driversService: DriversService,
  ) { }

  get drivers(): Driver[] {
    return this.driversService.drivers;
  }

  update(csvContent: string) {
    this.driversService.setFromCsv(csvContent);
    this.driversChanged.emit(this.driversService.drivers);
  }

  sanitize(content: string): string {
    return decodeURIComponent(escape(content));
  }
}
