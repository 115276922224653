import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AppCommonsModule } from '../app-commons/app-commons.module';
import { GateReadingsComponent } from './gate-readings/gate-readings.component';
import { ResultsImportComponent } from './results-import/results-import.component';
import { GepardRoutingModule } from './gepard-routing.module';
import { GateReadingsService } from './gate-readings/gate-readings.service';
import { CsvParserService } from './csv-parser.service';
import { ReadingTimesComponent } from './reading-times/reading-times.component';
import { TagsComponent } from './tags/tags.component';
import { TagsService } from './tags/tags.service';
import { FormsModule } from '@angular/forms';
import { GateReadingsListComponent } from './gate-readings/gate-readings-list/gate-readings-list.component';
import { TagsListComponent } from './tags/tags-list/tags-list.component';
import { ReadingTimesForAppComponent } from './reading-times-for-app/reading-times-for-app.component';
import { DriversComponent } from './drivers/drivers.component';
import { DriversListComponent } from './drivers/drivers-list/drivers-list.component';
import { DriversService } from './drivers/drivers.service';
import { SeasonsModule } from '../seasons/seasons.module';
import { RankingModule } from '../ranking/ranking.module';
import { ResultsImport2Component } from './results-import2/results-import2.component';
import { BackupResultsMergeComponent } from './backup-results-merge/backup-results-merge.component';
import { TagReadingsService } from './tag-readings/tag-readings.service';
import { ResultsImport3Component } from './results-import3/results-import3.component';
import { ToUniqueTagReadingIdPipe } from './tag-readings/to-unique-tag-reading-id.pipe';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    AppCommonsModule,
    SeasonsModule,
    RankingModule,
    GepardRoutingModule
  ],
  exports: [
  ],
  declarations: [
    GateReadingsComponent,
    ResultsImportComponent,
    ResultsImport2Component,
    ResultsImport3Component,
    ReadingTimesComponent,
    TagsComponent,
    GateReadingsListComponent,
    TagsListComponent,
    ReadingTimesForAppComponent,
    DriversComponent,
    DriversListComponent,
    ResultsImport2Component,
    BackupResultsMergeComponent,
    ToUniqueTagReadingIdPipe
  ],
  providers: [
    GateReadingsService,
    TagReadingsService,
    CsvParserService,
    TagsService,
    DriversService,
    ToUniqueTagReadingIdPipe
  ]
})
export class GepardModule { }
