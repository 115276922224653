import { AppRoutingModule } from '../app-routing.module';
import { CommonModule } from '@angular/common';
import { CountdownTimerComponent } from './countdown-timer/countdown-timer.component';
import { FormsModule } from '@angular/forms';
import { ListComponent } from './list/list.component';
import { NgModule } from '@angular/core';
import { OpenUntilPipe } from './pipes/open-until.pipe';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { SortableDirective } from './list/sortable.directive';
import { TimeGapHoursPipe } from './time-gap/time-gap-hours.pipe';
import { TimeGapHumanizePipe } from './time-gap/time-gap-humanize.pipe';
import { FileReaderService } from './file-reader/file-reader.service';
import { MocksService } from './mocks/mocks.service';
import { FileWatcherComponent } from './file-watcher/file-watcher.component';
import { NormalizeLocalISOStringPipe } from './pipes/normalize-timestamp.pipe';

@NgModule({
  imports: [
    AppRoutingModule,
    CommonModule,
    FormsModule,
    PopoverModule
  ],
  exports: [
    ListComponent,
    OpenUntilPipe,
    FileWatcherComponent,
    PopoverModule
  ],
  declarations: [
    CountdownTimerComponent,
    ListComponent,
    OpenUntilPipe,
    SortableDirective,
    TimeGapHoursPipe,
    TimeGapHumanizePipe,
    FileWatcherComponent,
    NormalizeLocalISOStringPipe
  ],
  providers: [
    TimeGapHoursPipe,
    TimeGapHumanizePipe,
    FileReaderService,
    NormalizeLocalISOStringPipe,
    MocksService
  ]
})
export class AppCommonsModule {}
