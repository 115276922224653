<div class="form-row">
  <label for="source-file"><ng-content></ng-content></label>
</div>
<div class="form-row">
  <div class="form-group col-10">
    <input type="file"
           class="form-control form-control-sm"
           id="source-file"
           name="sourceFile"
           (change)="sourceFile.files ? setInputFile(sourceFile.files[0]) : null"
           #sourceFile
    >
  </div>

  <div class="form-group col-2" *ngIf="showSwitch">
    <button class="btn btn-primary form-control"
            id="watch-switch"
            (click)="isStopped ? start() : stop()"
            [disabled]="!file"
            #switch
    >
      <i [class]="'fa ' + (isStopped ? 'fa-play' : 'fa-pause')"></i>
    </button>
  </div>
</div>
