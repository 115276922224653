<!--<mc-standings-moderation-->
	<!--(standingsConfigChanged)="onStandingsConfigUpdate($event)"-->
	<!--(resultsConfigChanged)="onResultsConfigUpdate($event)"-->
<!--&gt;</mc-standings-moderation>-->

<mc-race-ranking-standings-list
	[race]="race"
	[standings]="filteredStandings"
	[resultsConfig]="resultsConfig"
></mc-race-ranking-standings-list>
