import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MocksService } from '../../app-commons/mocks/mocks.service';
import { map, mergeMap, switchMap, takeUntil, tap, toArray } from 'rxjs/operators';
import { GateReadingsService } from '../gate-readings/gate-readings.service';
import { ResultsService } from '../../ranking/results/results.service';
import {
  BehaviorSubject,
  from,
  merge,
  Observable,
  ReplaySubject,
  Subject,
  Subscription
} from 'rxjs';
import { Driver } from '../drivers/driver';
import { StandingsConfig } from '../../ranking/standings/standings-config';
import { ResultsConfig } from '../../ranking/results/results-config/results-config';
import { Result } from '../../ranking/results/result/result';
import { RankingsService } from '../../ranking/rankings.service';
import { Ranking } from '../../ranking/ranking';
import { RacesService } from '../../ranking/races/races.service';
import { Race } from '../../ranking/races/race';
import { StandingsService } from '../../ranking/standings/standings.service';
import { StandingsFilterPipe } from '../../ranking/standings/standings-filter.pipe';
import { DriversService } from '../drivers/drivers.service';
import { ResultsImport3Service } from './results-import3.service';
import { RemoteResultsService } from '../../ranking/results/remote-results.service';
import { Standing } from '../../ranking/standing';
import { RfidSolutionsTagArriveReadingsService } from '../tag-readings/rfid-solutions-tag-arrive-readings.service';
import { TagReading } from '../tag-readings/tag-reading';
import { TagReadingsService } from '../tag-readings/tag-readings.service';

@Component({
  selector: 'mc-results-import3',
  templateUrl: './results-import3.component.html',
  styleUrls: ['./results-import3.component.scss']
})
export class ResultsImport3Component implements OnInit, OnDestroy, AfterViewInit {

  readonly RFID_ACCURACY_THRESHOLD = 2;

  race: Race;
  rankings: Ranking[] = []; // Rankings of the race.
  standings: Standing[] = [];

  tagReadings: TagReading[] = [];

  drivers$ = new ReplaySubject<Driver[]>(1);
  driversFromCsv$ = new Subject<Driver[]>();
  standingsConfig$ = new BehaviorSubject<StandingsConfig>({});
  resultsConfig$ = new BehaviorSubject<ResultsConfig>({});

  tagReadings$ = new BehaviorSubject<TagReading[]>([]);

  resolveSubscription: Subscription;
  resultSubscription: Subscription;
  dbConnectionSubscription: Subscription;
  syncSubscription: Subscription;

  jsonExport = null;
  showLocalResultsPreview = false;

  resultsFromTagReadings$: Observable<Result[]> = this.drivers$.pipe(
    switchMap((drivers: Driver[]) => this.tagReadings$.pipe(
      tap((tagReadings: TagReading[]) => this.tagReadings = tagReadings),
      mergeMap((tagReadings: TagReading[]) => from(tagReadings).pipe(
        map((tagReading: TagReading) => [tagReading, this.RFID_ACCURACY_THRESHOLD]),
        this.tagReadingsService.filterThresholdOperator(),
        // withLatestFrom(this.resultsConfig$),
        // map(([tagReading, resultsConfig]: [TagReading, ResultsConfig]) => [tagReading, resultsConfig.minimumLapSeconds, resultsConfig.maximumLapSeconds]),
        // this.tagReadingsService.filterLaptimeDurationOperator,
        map(tagReading => [tagReading, drivers, this.race.resultsConfig]),
        this.tagReadingsService.mapToResultsOperator(),
        toArray()
      ))
    ))
  );

  constructor(
    private readonly mocks: MocksService,
    private readonly gateReadingsService: GateReadingsService,
    private readonly tagReadingsService: TagReadingsService,
    public readonly resultsService: ResultsService,
    private readonly rankingsService: RankingsService,
    private readonly racesService: RacesService,
    private readonly standingsService: StandingsService,
    private readonly standingsFilter: StandingsFilterPipe,
    private readonly driversService: DriversService,
    private readonly resultsImport3Service: ResultsImport3Service,
    public readonly remoteResultsService: RemoteResultsService,
    private readonly rfidSolutionsTagArriveReadingsService: RfidSolutionsTagArriveReadingsService
  ) {
  }

  ngOnInit() {
    this.resolveSubscription = this.resultsImport3Service.loadRace().pipe(
      tap(([race, rankings, drivers$]) => {
        this.race = race;
        this.rankings = rankings;
        this.resultsConfig$.next(this.race.resultsConfig);
      }),
      // tap(v => console.log(11, 'Loading race with rankings: ', v)),
      switchMap(([race, rankings, drivers$]) => merge(
        drivers$.pipe(
          takeUntil(this.driversFromCsv$)
        ),
        this.driversFromCsv$
      ).pipe(
        // tap(v => console.log(12, 'Loading drivers from Spreadsheet or CSV: ', v)),
        tap(drivers => this.driversService.drivers = drivers),
        tap(drivers => this.drivers$.next(drivers)),
        switchMap(drivers => this.resultsFromTagReadings$),
        // tap(v => console.log(13, 'Loading results from TagReadings: ', v)),
        tap(results => {
          const changed = this.resultsService.mergeToState(results);
          console.log('state: ' + this.resultsService.results.length + ', changed: ' + changed.length);
          this.recalculateRankings(this.standingsService.fromResults(this.resultsService.results));
          this.resultsService.broadcastChanges(changed);
          if (this.remoteResultsService.isSyncing()) {
            this.remoteResultsService.db.bulkDocs(changed);
          }
        })
      ))
    ).subscribe();
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    if (this.resolveSubscription) {
      this.resolveSubscription.unsubscribe();
    }
    if (this.resultSubscription) {
      this.resultSubscription.unsubscribe();
    }
    if (this.syncSubscription) {
      this.syncSubscription.unsubscribe();
    }
    this.resultsService.replaceState([]);
  }

  trackRanking(index: number, ranking: Ranking) {
    return ranking.id;
  }

  recalculateRankings(standings: Standing[]) {
    this.standings = standings.map(standing => this.standingsService.getRecalculated(standing, this.race.resultsConfig));
  }

  updateStandingsConfig(standingsConfig: StandingsConfig) {
    this.standingsConfig$.next(standingsConfig);
  }

  updateResultsConfig(resultsConfig: ResultsConfig) {
    this.resultsConfig$.next(resultsConfig);
  }

  updateTagReadings(tagReadings: TagReading[]) {
    this.tagReadings$.next(tagReadings);
  }

  updateReadings(rawContent: string) {
    this.tagReadings$.next(this.parseInput(rawContent));
  }

  parseInput(rawContent: string): TagReading[] {
    const lead = rawContent.slice(0, 200);
    let tagReadings: TagReading[];
    if (this.rfidSolutionsTagArriveReadingsService.isValid(lead)) {
      tagReadings = this.rfidSolutionsTagArriveReadingsService.parseToTagReadings(rawContent);
    } else {
      tagReadings = this.gateReadingsService.parseToTagReadings(rawContent);
    }
    return tagReadings;
  }

  connect(dbName: string) {
    this.dbConnectionSubscription = this.remoteResultsService.connect(dbName).pipe(
      mergeMap(() => this.refreshResults())
    ).subscribe(newResults => {
      console.log('Connected to ' + dbName + '.');
    });
  }

  disconnect() {
    this.dbConnectionSubscription.unsubscribe();
    return this.remoteResultsService.disconnect();
  }

  isConnected() {
    return this.remoteResultsService.isConnected();
  }

  refreshResults() {
    console.log('Refreshing results...');
    return this.remoteResultsService.findAll().pipe(
      tap(results => console.log('Refreshed results', results.length)),
      map(results => this.resultsService.mergeToState(results)),
      tap(changed => console.log('Refreshed changes', changed.length)),
      tap(changed => this.resultsService.broadcastChanges(changed))
    );
  }

  sync(toggle) {
    if (toggle) {
      console.log('Turning sync on...');
    } else {
      console.log('Turning sync off...');
    }
    if (this.syncSubscription) {
      this.syncSubscription.unsubscribe();
    }
    if (toggle) {
      this.syncSubscription = this.remoteResultsService.syncOn(true, true).pipe(
        // takeWhile(state => !state),
        mergeMap(state => toggle ? this.refreshResults() : [[]])
      ).subscribe(newResults => {
        if (toggle) {
          console.log('Sync is now on.');
          console.log('Pulled ' + newResults.length + ' results after sync.');
        } else {
          console.log('Sync is now off.');
        }
      });
    } else {
      this.remoteResultsService.syncOff();
      console.log('Sync is now off.');
    }
  }

  exportTagReadings() {
    this.jsonExport = this.tagReadings;
  }

  exportResults() {
    this.jsonExport = this.resultsService.results;
  }

  clearState() {
    this.resultsService.clearState();
    this.updateTagReadings([]);
  }
}
