import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ResultsImport2Component } from './results-import2/results-import2.component';
import { BackupResultsMergeComponent } from './backup-results-merge/backup-results-merge.component';
import { ResultsImport3Component } from './results-import3/results-import3.component';

const gepardRoutes: Routes = [
  {path: 'gepard', component: ResultsImport2Component},
  {path: 'gepard/results-import2', component: ResultsImport2Component},
  {path: 'gepard/results-import3', component: ResultsImport3Component},
  {path: 'gepard/backup-results-merge', component: BackupResultsMergeComponent}
];

@NgModule({
  imports: [RouterModule.forChild(gepardRoutes)],
  exports: [RouterModule]
})
export class GepardRoutingModule {}
