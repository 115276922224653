import { Component, Input, OnInit } from '@angular/core';
import { GateReading } from '../gate-reading';

@Component({
  selector: 'mc-gate-readings-list',
  templateUrl: './gate-readings-list.component.html',
  styleUrls: ['./gate-readings-list.component.scss']
})
export class GateReadingsListComponent implements OnInit {

  @Input() gateReadings: GateReading[] = [];
  showCsvExport = false;

  constructor() { }

  ngOnInit() {
  }

}
