import { Injectable } from '@angular/core';
import { MocksService } from '../../app-commons/mocks/mocks.service';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Race } from './race';

@Injectable({
  providedIn: 'root'
})
export class RacesService {

  races: Race[] = [];

  constructor(
    private mocks: MocksService
  ) {
  }

  requestRaces(): Observable<Race[]> {
    return this.mocks.get('races/ac2024.json').pipe(
      map(json => JSON.parse(json))
    );
  }

  findById(raceId: number): Race | undefined {
    return this.races.find(race => race.id === raceId);
  }
}
