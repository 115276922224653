import { Injectable } from '@angular/core';
import { FileReaderService } from '../app-commons/file-reader/file-reader.service';

@Injectable()
export class CsvParserService {

  constructor(private fileReaderService: FileReaderService) {
  }

  parse(content: string): string[][] {
    return this.fileReaderService.parseCsv(this.sanitize(content), ';');
  }

  sanitize(content) {
    return content.replace(/^[^a-z0-9\s]+/giu, '');
  }
}
