<div *ngIf="race && rankings" [hidden]="loading">

	<div *ngIf="syncProgress" class="sync-progress-bar" [class.sync-complete]="syncProgress?.percent == 100">
		<div class="bar" [style.width.%]="syncProgress?.percent"></div>
		<div class="label">Live sync: <b>{{syncProgress?.percent}}%</b>, laps: <b>{{syncProgress?.lapsCount}}</b>, last at: <b>{{syncProgress?.lastLapTime.toDate() | date:'HH:mm:ss'}}</b></div>
	</div>

  <h1>{{race?.name}} results ({{race?.trackName}})</h1>

  <ul class="nav nav-tabs mb-2" id="rankings-tabs-labels" role="tablist">
    <li class="nav-item" *ngFor="let ranking of rankings; let first = first" [hidden]="ranking?.standings?.length < 1">
      <a
        class="nav-link"
        [class.active]="first"
        data-toggle="tab"
        id="ranking-tab-{{ranking.id}}"
        href="#ranking-tab-content-{{ranking.id}}"
				role="tab"
      >
        <span *ngIf="ranking.standingsConfig.group" class="grid-sector-badge grid-sector-{{ranking.standingsConfig.group}}">&nbsp;</span>
        {{ranking.name}}
      </a>
    </li>
  </ul>

  <div class="tab-content" id="rankings-tabs-contents">
    <div *ngFor="let ranking of rankings; let first = first"
         class="tab-pane"
         [class.active]="first"
         id="ranking-tab-content-{{ranking.id}}"
         role="tabpanel"
         [hidden]="ranking?.standings?.length < 1"
    >
      <h4>
        {{ranking.name}}
        <small>{{ranking.description}}</small>
      </h4>
      <mc-race-ranking-standings
				[race]="race"
				[resultsConfig]="race.resultsConfig"
				[standings]="ranking.standings"
      ></mc-race-ranking-standings>
    </div>
  </div>
</div>

<div *ngIf="loading">Loading...</div>
