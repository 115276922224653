import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { interval, Observable } from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  exhaustMap,
  map,
  retryWhen,
  startWith,
  take,
  tap,
} from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SpreadsheetService {

  API_URL = 'https://www.googleapis.com/drive/v3/files/';

  constructor(
    private http: HttpClient
  ) { }

  getBasicUrl(token: string) {
    return 'https://sheets.googleapis.com/v4/spreadsheets/' + token + '/values/Sheet1?alt=json&key=' + environment.GOOGLE_API_KEY;
  }

  /**
   * @see https://developers.google.com/drive/api/v3/reference/files#modifiedTime
   */
  // getMetadataUrl(token: string) {
  //   return this.API_URL + token + '?fields=modifiedTime,version&random=' + Math.random() + '&key=' + environment.GOOGLE_API_KEY;
  // }

  getValuesUrl(token: string) {
    // return 'https://sheets.googleapis.com/v4/spreadsheets/' + token + '/values/Sheet1?alt=json&key=' + environment.GOOGLE_API_KEY;
    return this.API_URL + token + '/export?mimeType=text/csv&key=' + environment.GOOGLE_API_KEY;
  }

  poll(fileId: string, options?: {
    pollingInterval?: number,
    retriesNumber?: number,
    retryDelay?: number
  }): Observable<string> {
    const pollingInterval = options && options.pollingInterval || 60 * 1000;
    const retryDelay = options && options.retryDelay || 5 * 1000;
    const retriesNumber = options && options.retriesNumber || 10;

    return interval(pollingInterval).pipe(
      startWith(0),
      // exhaustMap(i => this.http.get(this.getMetadataUrl(fileId), {
      //   headers: {
      //     'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      //     Pragma: 'no-cache',
      //     Expires: 'Sat, 01 Jan 2000 00:00:00 GMT'
      //   }
      // }).pipe(
      //   retryWhen(errors => errors.pipe(
      //     delay(retryDelay),
      //     take(retriesNumber)
      //   ))
      // )),
      // map((json: any) => json.modifiedTime),
      // distinctUntilChanged(),
      exhaustMap(() => this.http.get(this.getValuesUrl(fileId), {
        responseType: 'text'
      }).pipe(
        retryWhen(errors => errors.pipe(
          delay(retryDelay),
          take(retriesNumber)
        ))
      )),
      distinctUntilChanged((prev, curr) => {
        if (prev === curr) {
          console.log('Drivers list downloaded, but no changes detected.');
        } else {
          console.log('Drivers list updated.');
        }
        return false;
      }),
    ) as Observable<string>;
  }
}
