import { Injectable } from '@angular/core';
import {
  distinctUntilChanged,
  filter,
  groupBy,
  map,
  mergeMap,
  pairwise,
  scan
} from 'rxjs/operators';
import { Observable, OperatorFunction } from 'rxjs';
import { MocksService } from '../../app-commons/mocks/mocks.service';
import { ResultsConfig } from '../../ranking/results/results-config/results-config';
import { Result } from '../../ranking/results/result/result';
import { ResultsService } from '../../ranking/results/results.service';
import { ResultsFilterPipe } from '../../ranking/results/results-config/results-filter.pipe';
import { Driver } from '../drivers/driver';
import { TagReading } from './tag-reading';
import { CsvParserService } from '../csv-parser.service';
import { ToUniqueResultIdPipe } from '../../ranking/results/result/to-unique-result-id.pipe';
import { DriversService } from '../drivers/drivers.service';

@Injectable({
  providedIn: 'root'
})
export class TagReadingsService {

  constructor(
    private mocks: MocksService,
    private csvParserService: CsvParserService,
    private driversService: DriversService,
    private resultsFilterPipe: ResultsFilterPipe,
    private readonly toUniqueResultIdPipe: ToUniqueResultIdPipe
  ) {
  }

  static merge(a: TagReading[], b: TagReading[]): TagReading[] {
    return TagReadingsService.findDuplicates(TagReadingsService.concat(a, b))[0];
  }

  static concat(...tagReadingsCollections: TagReading[][]): TagReading[] {
    let merged: TagReading[] = [];
    tagReadingsCollections.forEach((tagReadings: TagReading[]) => {
      merged = merged.concat(tagReadings);
    });
    return TagReadingsService.sort(merged);
  }

  static compare(a: TagReading, b: TagReading): number {
    return a.timestamp - b.timestamp;
  }

  static isSameId(a: TagReading, b: TagReading): boolean {
    return a._id === b._id;
  }

  static sort(tagReadings: TagReading[]): TagReading[] {
    return tagReadings.sort(TagReadingsService.compare);
  }

  static findDuplicates(tagReadings: TagReading[]): [TagReading[], TagReading[]] {
    const uniques = {};
    const duplicates = [];
    tagReadings.forEach(tagReading => {
      if (tagReading._id in uniques) {
        duplicates.push(tagReading);
      } else {
        uniques[tagReading._id] = tagReading;
      }
    });
    return [
      Object.values(uniques),
      duplicates
    ];
  }

  filterThresholdOperator(): OperatorFunction<[TagReading, number], TagReading> {
    return (stream: Observable<[TagReading, number]>): Observable<TagReading> => {
      let threshold;
      return stream.pipe(
        map(([tagReading, thresholdSeconds]: [TagReading, number]) => {
          threshold = (thresholdSeconds || 2) * 1000 * 1000;
          return [tagReading, this.driversService.findByEpc(tagReading.tagCode)?.startNumber];
        }),
        groupBy(([tagReading, startNumber]: [TagReading, number]) => startNumber || tagReading),
        mergeMap(group => group.pipe(
          map(([tagReading, startNumber]) => tagReading),
          scan((previousLapStart: TagReading, tagReading: TagReading) => {
            const diff = (tagReading.timestamp - previousLapStart.timestamp) * 1000;
            if (diff >= threshold) {
              return tagReading;
            } else {
              return previousLapStart;
            }
          }),
          distinctUntilChanged(TagReadingsService.isSameId)
        ))
      );
    }
  }

  filterLaptimeDurationOperator(stream: Observable<[TagReading, number, number]>): Observable<TagReading> {
    let minimumLapDuration;
    let maximumLapDuration;
    return stream.pipe(
      map(([tagReading, minimumLapSeconds, maximumLapSeconds]: [TagReading, number, number]) => {
        minimumLapDuration = (minimumLapSeconds || 30) * 1000 * 1000;
        maximumLapDuration = (maximumLapSeconds || 360) * 1000 * 1000;
        return [tagReading, this.driversService.findByEpc(tagReading.tagCode)?.startNumber];
      }),
      groupBy(([tagReading, startNumber]: [TagReading, number]) => startNumber || tagReading),
      mergeMap(group => group.pipe(
        map(([tagReading, startNumber]) => tagReading),
        scan((lastLapStart: TagReading, tagReading: TagReading) => {
          const diff = (new Date(tagReading.timestamp).getTime() - new Date(lastLapStart.timestamp).getTime()) * 1000;
          if (diff >= minimumLapDuration && diff <= maximumLapDuration) {
            return tagReading;
          } else {
            return lastLapStart;
          }
        }),
        distinctUntilChanged(TagReadingsService.isSameId)
      ))
    );
  }

  mapToResultsOperator(): OperatorFunction<[TagReading, Driver[], ResultsConfig], Result> {
    return (stream: Observable<[TagReading, Driver[], ResultsConfig]>): Observable<Result> => {
      let resultsConfig: ResultsConfig;
      let drivers: Driver[] = [];

      return stream.pipe(
        map(([tagReading, newDrivers, newResultsConfig]: [TagReading, Driver[], ResultsConfig]) => {
          resultsConfig = newResultsConfig;
          drivers = newDrivers;
          return [tagReading, this.driversService.findByEpc(tagReading.tagCode)?.startNumber];
        }),
        groupBy(([tagReading, startNumber]: [TagReading, number]) => startNumber || tagReading),
        mergeMap(group => group.pipe(
          map(([tagReading, startNumber]) => tagReading),
          pairwise(),
          map(([start, finish]: [TagReading, TagReading]): Result | null => {
            const driver = drivers.find(driver => driver.epcs.includes(finish.tagCode));
            if (driver) {
              const diff = (finish.timestamp - start.timestamp) * 1000;
              return {
                _id: this.toUniqueResultIdPipe.transform(finish.timestamp, finish.tagCode),
                driverId: driver.startNumber,
                startEpc: start.tagCode,
                finishEpc: finish.tagCode,
                microtime: diff,
                createdAt: finish.timestamp,
                correction: 0,
                verification: null
              };
            } else {
              return null;
            }
          }),
          // filter((result: Result) => result !== null && this.resultsFilterPipe.transform([result], resultsConfig).length > 0),
          filter((result: Result) => result !== null),
          distinctUntilChanged(ResultsService.isSameId)
        ))
      );
    }
  }
}
