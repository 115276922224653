import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';

@Pipe({
  name: 'laptimeFilter'
})
export class LaptimeFilterPipe implements PipeTransform {

  constructor(
    private decimalPipe: DecimalPipe,
    private datePipe: DatePipe
  ) {
  }

  transform(microtime: number, format?: 'minutes' | 'seconds'): string {
    if (format === 'minutes') {
      return this.datePipe.transform(Math.round(microtime / 1000), 'm:ss.SSS', 'UTC');
    } else {
      return this.decimalPipe.transform(microtime / 1000000, '.3-3');
    }
  }
}
