import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { McApiConnectorService } from './mc-api-client-connector.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [McApiConnectorService]
})
export class McApiClientModule {
}
