import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MocksService {

  constructor(private http: HttpClient) { }

  get(fileName: string) {
    return this.http.get('assets/mock/' + fileName, {
      responseType: 'text'
    });
  }
}
