import { Component, OnInit } from '@angular/core';
import { RacesService } from './races.service';
import { Observable } from 'rxjs';
import { Race } from './race';

@Component({
  selector: 'mc-races',
  templateUrl: './races.component.html',
  styleUrls: ['./races.component.scss']
})
export class RacesComponent implements OnInit {


  races$: Observable<Race[]> = this.racesService.requestRaces();

  constructor(
    public racesService: RacesService
  ) { }

  ngOnInit() {

  }

}
