<form novalidate #form="ngForm">
  <div class="form-row">
    <div class="col-3">
      <label class="col-form-label col-form-label-sm" for="category">Power category:</label>
      <select
        id="category"
        class="form-control form-control-sm"
        [value]="categoryIndex"
        (change)="categoryIndex = $event.target.value; category = categories[categoryIndex]"
      >
        <option *ngFor="let categoryPreset of categories; let i = index" [value]="i">{{ categoryPreset.label }}</option>
      </select>
    </div>
  </div>

  <div class="form-row">
    <div class="col-3">
      <label class="col-form-label col-form-label-sm" for="women">Driver gender:</label>
      <select
        id="women"
        class="form-control form-control-sm"
        [value]="women"
        (change)="women = $event.target.value"
      >
        <option value="">All genders</option>
        <option value="1">Women only</option>
        <option value="0">Men only</option>
      </select>
    </div>

    <div class="col-3">
      <label class="col-form-label col-form-label-sm" for="group">Grid group color:</label>
      <select
        id="group"
        class="form-control form-control-sm"
        [value]="group"
        (change)="group = $event.target.value"
      >
        <option value="">All groups</option>
        <option value="1">Green</option>
        <option value="2">Red</option>
        <option value="3">Yellow</option>
        <option value="4">Blue</option>
        <option value="5">Black</option>
        <option value="6">White</option>
        <option value="7">Orange</option>
        <option value="8">Purple</option>
      </select>
    </div>
  </div>

  <div class="form-row">
    <div class="col-3">
      <label class="col-form-label col-form-label-sm" for="ranking-laps-number">Ranking laps number:</label>
      <input type="number"
             class="form-control form-control-sm"
             id="ranking-laps-number"
             name="rankingLapsNumber"
             [value]="rankingLapsNumber"
             (change)="rankingLapsNumber = $event.target.value"
      />
    </div>

    <div class="col-3">
      <label class="col-form-label col-form-label-sm" for="minimum-lap-seconds">Minimal laptime (s):</label>
      <input type="number"
             class="form-control form-control-sm"
             id="minimum-lap-seconds"
             name="minimumLapSeconds"
             [value]="minimumLapSeconds"
             (change)="minimumLapSeconds = $event.target.value"
      >
    </div>

    <div class="col-3">
      <label class="col-form-label col-form-label-sm" for="maximum-lap-seconds">Maximal laptime (s):</label>
      <input type="number"
             class="form-control form-control-sm"
             id="maximum-lap-seconds"
             name="maximumLapSeconds"
             [value]="maximumLapSeconds"
             (change)="maximumLapSeconds = $event.target.value"
      >
    </div>
  </div>
</form>
