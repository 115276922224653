import { Injectable } from '@angular/core';
import * as PouchDB from 'pouchdb/dist/pouchdb';
import { environment } from '../../../environments/environment';
import { Result } from './result/result';
import { Subject, BehaviorSubject, Observable, from, throwError } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { ResultsService } from './results.service';
import { ResultsSyncEvent } from './results-sync-event';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class RemoteResultsService {

  db: PouchDB = null;
  private remote: PouchDB = null;
  private syncProcess: PouchDB = null;
  private lastSync: ResultsSyncEvent;

  sync$ = new Subject<ResultsSyncEvent>();
  syncState$ = new BehaviorSubject<boolean>(false);
  dbName = '';

  constructor(
    private resultsService: ResultsService
  ) {
  }

  connect(dbName: string) {
    console.log('Changing database to: ', dbName);
    if (dbName.length > 0) {
      this.disconnect();
      this.dbName = dbName;
      this.remote = new PouchDB(environment.pouchDbUrl + dbName);
      return from(this.remote.info()).pipe(
        mergeMap(info => {
          this.db = new PouchDB(dbName, {auto_compaction: true});
          return from(this.db.info());
        })
      );
    } else {
      return throwError('Expecting database name.');
    }
  }

  broadcastSyncEvent({createdAt = undefined, pending = undefined, processed = undefined, total = undefined, data = undefined}): ResultsSyncEvent {
    let lastSync = {
      createdAt: createdAt !== undefined ? createdAt : (this.lastSync?.createdAt || +moment()),
      pending: pending !== undefined ? !!pending : (this.lastSync?.pending || false),
      processed: processed !== undefined ? processed : (this.lastSync?.processed || 0),
      total: total !== undefined ? total : (this.lastSync?.total || 0),
      data: undefined
    };
    if (data) {
      lastSync.data = data;
    }
    this.sync$.next(lastSync);
    this.lastSync = lastSync;
    return lastSync;
  }

  stopSyncEventBroadcast() {
    this.broadcastSyncEvent({pending: false, total: 0, processed: 0});
    // this.lastSync = undefined;
    // this.sync$.next(this.lastSync);
  }

  isConnected(): boolean {
    return !!this.db && !!this.remote;
  }

  isSyncing(): boolean {
    return !!this.syncProcess;
  }

  disconnect() {
    if (this.syncProcess) {
      this.syncProcess.cancel();
      this.syncProcess = null;
    }
    if (this.db) {
      this.db.close();
      this.db = null;
    }
    if (this.remote) {
      this.remote.close();
      this.remote = null;
    }
  }

  syncOn(from?: boolean, to?: boolean) {
    const batch_size = 100;
    const batches_limit = 10;
    from = !!from;
    to = !!to;
    if (this.isConnected()) {

      if (this.syncProcess) {
        this.syncProcess.cancel();
      }

      if (from && to) {
        this.syncProcess = this.db.sync(this.remote, {
          live: true,
          retry: true,
          batch_size,
          batches_limit
        });
      } else if (from) {
        this.syncProcess = this.db.replicate.from(this.remote, {
          live: true,
          retry: true,
          batch_size,
          batches_limit
        });
      } else if (to) {
        this.syncProcess = this.db.replicate.to(this.remote, {
          live: true,
          retry: true,
          batch_size,
          batches_limit
        });
      } else {
        this.syncProcess = null;
      }

      if (this.syncProcess) {
        this.syncProcess
          .on('complete', () => {
            console.log('Sync finished.');
            this.stopSyncEventBroadcast();
          })
          .on('change', event => {
            const oneWay = !from || !to;
            const change = oneWay ? event : event.change;
            const changed = this.resultsService.mergeToState(change.docs.map((result: Result) => this.resultsService.normalizeResult(result)));
            this.db.info()
              .then(info => this.broadcastSyncEvent({
                createdAt: change.docs && change.docs.length ? change.docs[change.docs.length - 1].createdAt : undefined,
                pending: true,
                processed: this.resultsService.results?.length || 0,
                total: Math.max(info.doc_count, change.docs_read + change.pending),
                data: change.docs
              }));
            if (changed && changed.length > 0) {
              this.resultsService.broadcastChanges(changed);
            }
            if (oneWay || event.direction === 'pull') {
              console.log('Sync pull + ' + change.docs.length + ' docs (drivers: ' + (change.docs.length > 0 ? change.docs.map(r => r.driverId) : '-') + ').');
              // const changed = this.resultsService.mergeToState(change.docs.map(result => ({...result, ...{createdAt: this.normalizeLocalISOString.transform(result.createdAt)}})));
              // this.db.info()
              //   .then(info => this.broadcastSyncEvent({
              //     createdAt: change.docs && change.docs.length ? +moment(change.docs[change.docs.length - 1].createdAt) : undefined,
              //     pending: true,
              //     processed: this.resultsService.results?.length || 0,
              //     total: Math.max(info.doc_count, change.docs_read + change.pending),
              //     data: change.docs
              //   }));
              // if (changed && changed.length > 0) {
              //   this.resultsService.broadcastChanges(changed);
              // }
            } else if (event.direction === 'push') {
              console.log('Sync push ' + change.docs.length + ' docs.');
              // this.resultsService.broadcastChanges(event.change.docs);
            }
          })
          .on('paused', () => {
            console.log('Sync paused.');
            this.broadcastSyncEvent({
              pending: false
            });
            this.syncState$.next(false);
          })
          .on('active', () => {
            console.log('Sync resumed.');
            this.broadcastSyncEvent({
              pending: true
            });
            this.syncState$.next(true);
          })
          .on('error', error => console.log('Sync error: ', error));
      }
    }

    return this.syncState$;
  }

  syncOff() {
    if (this.isConnected()) {
      if (this.syncProcess) {
        this.syncProcess.cancel();
        this.syncProcess = undefined;
      }
    }
    this.stopSyncEventBroadcast();
  }

  findAll(): Observable<Result[]> {
    return from(this.db.allDocs({ include_docs: true })).pipe(
      filter<any>(response => !!response),
      tap(response => this.broadcastSyncEvent({ total: response.total_rows, processed: response.total_rows })),
      map(response => response['rows'].map(row => this.resultsService.normalizeResult(row.doc)))
    );
  }

  save(result: Result): Promise<Result> {
    return this.db.put(result)
      .then(updatedResult => {
        console.log('Result #' + updatedResult._id + ' successfully saved.');
        return this.db.get(updatedResult._id);
      })
      .catch(error => {
        console.error('Result save error', error, result);
      });
  }

  verify(result: Result, isValid: boolean | null) {
    result.verification = isValid;
    return this.save(result);
  }

  restore(result: Result) {
    return this.verify(result, true);
  }

  reject(result: Result) {
    return this.verify(result, false);
  }

  unverify(result: Result) {
    return this.verify(result, null);
  }

  remove(id: number) {
    return this.db.get(id)
      .then(result => this.db.remove(result))
      .catch(error => console.error('Removing result #' + id + 'failed.'));
  }
}
