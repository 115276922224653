import { Pipe, PipeTransform } from '@angular/core';
import { TimeGapHoursPipe } from './time-gap-hours.pipe';

@Pipe({
  name: 'timeGapHumanize'
})
export class TimeGapHumanizePipe extends TimeGapHoursPipe implements PipeTransform {

  transform(value: any, args?: string): string {
    if (args === 'inverted') {
      return this.invertedTimeGap.set(value).humanized();
    } else {
      return this.timeGap.set(value).humanized();
    }
  }
}
