import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map} from 'rxjs/operators';
import { Legacy2022Result, Result } from './result/result';
import { MocksService } from '../../app-commons/mocks/mocks.service';
import { NormalizeLocalISOStringPipe } from '../../app-commons/pipes/normalize-timestamp.pipe';

@Injectable({
  providedIn: 'root'
})
export class ResultsService {

  results: Result[] = [];
  results$ = new BehaviorSubject<Result[]>([]);

  constructor(
    private readonly mocks: MocksService,
    private readonly normalizeILocalSOStringPipe: NormalizeLocalISOStringPipe
  ) {
  }

  static areDeepEqual(a: Result, b: Result) {
    return a._id === a._id
      && a.driverId === b.driverId
      && a.startEpc === b.startEpc
      && a.finishEpc === b.finishEpc
      && a.microtime === b.microtime
      && a.correction === b.correction
      && a.verification === b.verification
      && a._rev === b._rev;
  }

  static isSameId(a: Result, b: Result) {
    return a._id === b._id;
  }

  normalizeResult(result: Result | Legacy2022Result): Result {
    if ('startEpc' in result && 'finishEpc' in result) {
      // 2023 `result` object format
      return result;
    }

    // Legacy (2022 `result` object format)
    return {
      _id: result._id,
      driverId: result.driverId,
      startEpc: '',
      finishEpc: '',
      microtime: result.microtime,
      createdAt: new Date(this.normalizeILocalSOStringPipe.transform(result.createdAt)).getTime(),
      correction: result.correction,
      verification: result.verification,
      _rev: result._rev,
    };
  }

  requestResults(mockPath: string): Observable<Result[]> {
    return this.mocks.get(mockPath).pipe(
      map(jsonContent => JSON.parse(jsonContent)),
      map(results => results.map(result => this.normalizeResult(result)))
    );
  }

  findById(_id: string): (Result | undefined) {
    return this.results.find(result => result._id === _id);
  }

  findByCreatedAt(createdAt: number): (Result | undefined) {
    return this.results.find(result => result.createdAt === createdAt);
  }

  replaceState(newResults: Result[]) {
    this.results = newResults;
  }

  /**
   * Merges results into state and returns changed.
   * Replaces result if changed.
   * Keeps result in state ordered.
   *
   * @see areDeepEqual
   * @param {Result[]} newResults
   * @returns {Result[]}
   */
  mergeToState(newResults: Result[]): Result[] {
    const changed = [];
    newResults.forEach(newResult => {
      const oldResult = this.findById(newResult._id);
      if (!oldResult) {
        if (!this.findByCreatedAt(newResult.createdAt)) {
          this.results.push(newResult);
          changed.push(newResult);
        }
      } else if ('_deleted' in newResult && newResult['_deleted'] === true) {
        this.results.splice(this.results.indexOf(oldResult), 1);
        changed.push(newResult);
      } else if (!ResultsService.areDeepEqual(oldResult, newResult)) {
        this.results.splice(this.results.indexOf(oldResult), 1, newResult);
        changed.push(newResult);
      }
    });
    this.sortState();
    return changed;
  }

  sortState() {
    // TODO Should't it be sorted by time instead of ids?
    // this.results.sort((a, b) => a._id - b._id);
    this.results.sort((a, b) => a.createdAt - b.createdAt);
  }

  clearState() {
    this.replaceState([]);
    this.broadcastChanges([]);
  }

  broadcastChanges(changedResults: Result[]) {
    this.results$.next(changedResults);
  }
}
