import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeWhile } from 'rxjs/operators';
import { FileReaderService } from '../file-reader/file-reader.service';

@Component({
  selector: 'mc-file-watcher',
  templateUrl: './file-watcher.component.html',
  styleUrls: ['./file-watcher.component.scss']
})
export class FileWatcherComponent implements OnInit, OnDestroy {

  file: File = null;
  showSwitch = true;
  protected isAutostart = false;
  protected watchingFrequency = 1000; // milliseconds

  private sourceFileWatcher$: Observable<any>;
  private watching: Subscription;

  @ViewChild('sourceFile') sourceFile: ElementRef;

  @Input() set autostart(autostart: boolean) {
    this.isAutostart = autostart;
  }

  @Input() set switch(show: boolean) {
    this.showSwitch = show || !this.isAutostart;
  }

  @Input() set frequency(seconds: string|number) {
    this.watchingFrequency = +seconds > 0 ? (+seconds) * 1000 : 1000;
  }

  @Output() changed = new EventEmitter<string>();

  constructor(private fileWatcherService: FileReaderService) {
  }

  ngOnInit(): void {
    this.sourceFileWatcher$ = timer(0, this.watchingFrequency).pipe(
      // tap(c => console.log('timer', c)),
      takeWhile(() => !!this.file && !this.isStopped),
      map(() => this.file.name + '-' + this.file.lastModified),
      distinctUntilChanged((a, b) => a === b),
      switchMap(() => this.fileWatcherService.readFile(this.file)),
      // progress preview here...,
      filter((fileReadProgressEvent: any) => fileReadProgressEvent.complete),
      map(fileReadProgressEvent => fileReadProgressEvent.content)
    );
  }

  get isStopped(): boolean {
    return !this.watching || this.watching.closed;
  }

  stop(): void {
    if (this.watching) {
      this.watching.unsubscribe();
    }
  }

  start(): void {
    if (!this.watching || this.watching.closed) {
      this.watching = this.sourceFileWatcher$
        .subscribe(fileContent => this.changed.emit(fileContent));
    }
  }

  setInputFile(file: File): void {
    if (file) {
      this.file = file;
      if (this.isAutostart || !this.isStopped) {
        this.start();
      }
    } else {
      this.file = null;
    }
  }

  ngOnDestroy(): void {
    this.stop();
  }
}
