import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toUniqueTagReadingId'
})
export class ToUniqueTagReadingIdPipe implements PipeTransform {

  /**
   * @param timestamp Lap finish millisecond timestamp.
   * @param tagCode
   * @returns {string} A derived ID, composed by concatenating two elements:
   *  - UTC ISO date string ("2023-03-27T20:59:56.578Z") with all non-digit characters removed, so it becomes "20230327205956578",
   *  - a tail of the tag code string, left-padded with zeros to the total lenght of 12 characters.
   */
  transform(timestamp: number, tagCode: string): string {
    return (new Date(timestamp).toISOString().replace(/[^0-9]/g, '')).padStart(17, '0')
      /*+ tagCode.slice(-12).padStart(12, '0')*/;
  }
}
