<form novalidate>
	<label>
		<input type="checkbox" (change)="showCsvExport = $event.target.checked" />
		{{gateReadings.length}} gate readings.
	</label>

	<div *ngIf="showCsvExport">
		<hr />
		<div *ngFor="let gateReading of gateReadings">
			{{gateReading ? gateReading.gr_id : '-'}};
			{{gateReading ? gateReading.gate_id : '-'}};
			{{gateReading ? gateReading.tag_code : '-'}};
			{{gateReading ? gateReading.first_detection_date : '-'}};
			{{gateReading ? gateReading.last_detection_date : '-'}};
			{{gateReading ? gateReading.save_date : '-'}};
			{{gateReading ? gateReading.status : '-'}};
			{{gateReading ? gateReading.lap : '-'}}
		</div>
	</div>
</form>
