import { Component, Input, OnInit } from '@angular/core';
import { Driver } from '../driver';

@Component({
  selector: 'mc-drivers-list',
  templateUrl: './drivers-list.component.html',
  styleUrls: ['./drivers-list.component.scss']
})
export class DriversListComponent implements OnInit {

  @Input() drivers: Driver[] = [];
  showCsvExport = false;

  constructor() { }

  ngOnInit() {
  }

}
