import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StandingsConfig } from '../standings-config';
import { ResultsConfig } from '../../results/results-config/results-config';

type TCategoryPreset = {
  label: string,
  category?: number[]
};

@Component({
  selector: 'mc-standings-moderation',
  templateUrl: './standings-moderation.component.html',
  styleUrls: ['./standings-moderation.component.scss']
})
export class StandingsModerationComponent implements OnInit {

  categories: TCategoryPreset[] = [
    { label: 'All categories' },
    { label: 'Do 450 KM', category: [1] },
    { label: 'Powyżej 450 KM', category: [2] },
    { label: 'OPEN', category: [10] }
  ];

  categoryIndex: number;

  @Input() standingsConfig: StandingsConfig;
  @Input() resultsConfig: ResultsConfig;

  @Output() standingsConfigChanged = new EventEmitter<StandingsConfig>();
  @Output() resultsConfigChanged = new EventEmitter<ResultsConfig>();


  set group(group: string|'') {
    this.standingsConfig.group = group === '' ? undefined : +group;
    this.emitStandingsConfig();
  }

  get group(): string {
    return this.standingsConfig.group === undefined ? '' : '' + this.standingsConfig.group;
  }

  set category(spec: TCategoryPreset) {
    this.categoryIndex = this.categories.indexOf(spec);
    this.standingsConfig.category = this.categories[this.categoryIndex].category;
    this.emitStandingsConfig();
  }

  get category(): TCategoryPreset {
    return this.categories[this.categoryIndex];
  }

  set women(women: string|'') {
    this.standingsConfig.women = women === '' ? undefined : !!+women;
    this.emitStandingsConfig();
  }

  get women(): string {
    return this.standingsConfig.women === undefined ? '' : +this.standingsConfig.women + '';
  }

  set minimumLapSeconds(n: number) {
    this.resultsConfig.minimumLapSeconds = +n;
    this.emitResultsConfig();
  }

  get minimumLapSeconds(): number {
    return this.resultsConfig.minimumLapSeconds;
  }

  set maximumLapSeconds(n: number) {
    this.resultsConfig.maximumLapSeconds = +n;
    this.emitResultsConfig();
  }

  get maximumLapSeconds(): number {
    return this.resultsConfig.maximumLapSeconds;
  }

  set rankingLapsNumber(n: number) {
    this.resultsConfig.rankingLapsNumber = +n;
    this.emitResultsConfig();
  }

  get rankingLapsNumber(): number {
    return this.resultsConfig.rankingLapsNumber;
  }

  constructor() { }

  ngOnInit() {
    this.emitStandingsConfig();
    this.emitResultsConfig();
  }

  emitStandingsConfig() {
    this.standingsConfigChanged.emit({... this.standingsConfig});
  }

  emitResultsConfig() {
    this.resultsConfigChanged.emit({... this.resultsConfig});
  }

}
