import { Pipe, PipeTransform } from '@angular/core';
import { ToUniqueTagReadingIdPipe } from '../../../gepard/tag-readings/to-unique-tag-reading-id.pipe';

@Pipe({
  name: 'toUniqueResultIdPipe'
})
export class ToUniqueResultIdPipe implements PipeTransform {

  constructor(
    private readonly toUniqueTagReadingIdPipe: ToUniqueTagReadingIdPipe
  ) {
  }

  /**
   * @see ToUniqueTagReadingIdPipe
   */
  transform(timestamp: number, tagCode: string): string {
    return this.toUniqueTagReadingIdPipe.transform(timestamp, tagCode);
  }
}
