<div class="row">
  <div class="col-4">
    <h4>Drivers CSV</h4>
    <mc-drivers
      (driversChanged)="driversFromCsv$.next($event)"
    ></mc-drivers>
  </div>
  <div class="col-4">
    <h4>GateReadings CSV</h4>
    <mc-gate-readings
      (updated)="updateGateReadings($event)"
    ></mc-gate-readings>

    <!--<button class="btn btn-primary" (click)="test2()">Run simulation</button>-->
  </div>

  <div class="col-4">
    <h4>Remote DB</h4>
    <div class="form-row">
      <div class="form-group">
        <input
          type="text"
          value="{{ race?.resultsCouchDbName }}"
          class="form-control-md"
          [disabled]="isConnected()"
          #dbNameInput
        />
        <button class="btn btn-primary" (click)="connect(dbNameInput.value)" *ngIf="!isConnected()">Connect</button>
        <button class="btn btn-primary" (click)="committing.checked = false; disconnect()" *ngIf="isConnected()">Disconnect</button>
      </div>
    </div>
    <div class="form-row" [hidden]="!isConnected()">
      <div class="form-group">
				<label for="commiting">
					<input
						type="checkbox"
						(change)="sync(committing.checked)"
						id="commiting"
						#committing
					/> Synchronize
				</label>
      </div>
    </div>
  </div>
</div>

<hr />

<div class="row">
  <div class="col-4">
    <h4>Laptimes</h4>
    <p>
      Number of laptimes: <b>{{resultsService.results.length}}</b>.
      <br />
			Last update changed: <b>{{(resultsService.results$ | async).length}}</b>.
      <br />
			<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exportModal" (click)="exportResults()">Export</button>
			&nbsp;
      <button class="btn btn-primary" (click)="clearState()">Reset</button>
    </p>
  </div>
</div>

<hr />

<div class="form-row">
	<div class="form-group">
		<label for="local-results-preview">
			<input
				type="checkbox"
				id="local-results-preview"
				(change)="showLocalResultsPreview = $event.target.checked"
			/> Local results preview
		</label>
	</div>
</div>

<div *ngIf="showLocalResultsPreview">
	<mc-standings-moderation
		(standingsConfigChanged)="updateStandingsConfig($event)"
		(resultsConfigChanged)="updateResultsConfig($event)"
	></mc-standings-moderation>

	<mc-race-ranking-standings
		[race]="race"
		[resultsConfig]="resultsConfig$ | async"
		[standingsConfig]="standingsConfig$ | async"
		[standings]="standings"
	></mc-race-ranking-standings>
</div>

<div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" id="exportModal">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLongTitle">JSON export</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<textarea class="json-export">{{ jsonExport | json }}</textarea>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
			</div>
		</div>
	</div>
</div>
