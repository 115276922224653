<div class="row">
  <div class="col-4">
    <h4>Laptimes</h4>
    <p>
      Number of laptimes: <b>{{results.length}}</b>.
      <br />
      Last update changed: <b>{{results.length}}</b>.
      <br />
      <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exportModal" (click)="exportResults()">Export</button>
      &nbsp;
<!--      <button class="btn btn-primary" (click)="clearState()">Reset</button>-->
    </p>
  </div>
</div>

<h1>Local results preview</h1>

<mc-standings-moderation
	(standingsConfigChanged)="standingsConfig$.next($event)"
	(resultsConfigChanged)="resultsConfig$.next($event)"
></mc-standings-moderation>

<mc-race-ranking-standings
	[race]="race$ | async"
	[resultsConfig]="resultsConfig$ | async"
	[standingsConfig]="standingsConfig$ | async"
	[standings]="(standings$ | async) || []"
></mc-race-ranking-standings>

<!--<table border>-->
	<!--<tbody>-->
		<!--<tr *ngFor="let result of results$ | async">-->
			<!--<td>{{ result.driverId }}</td>-->
			<!--<td>{{ result.createdAt }}</td>-->
			<!--<td>{{ result.microtime | laptimeFilter:'minutes' }}</td>-->
		<!--</tr>-->
	<!--</tbody>-->
<!--</table>-->

<div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" id="exportModal">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">JSON export</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <textarea class="json-export">{{ jsonExport | json }}</textarea>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
