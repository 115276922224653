import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class FileReaderService {

  constructor() {
  }

  readFile(file): Observable<any | { progress: number, complete: boolean, content?: string }> {
    const read = new Observable(emitter => {
      const reader = new FileReader();

      reader.onprogress = (e: ProgressEvent) => {
        if (e.lengthComputable) {
          emitter.next({
            progress: e.total > 0 ? e.loaded / e.total * 100 : 0,
            complete: false
          });
        }
      };

      reader.onload = (e: any) => {
        const data = e.target.result.split(',')[1];
        const content = data ? atob(data) : '';
        emitter.next({
          progress: 100,
          content: content,
          complete: true
        });
        emitter.complete();
      };

      // reader.readAsText(file, 'UTF-8');
      reader.readAsDataURL(file);
    });
    return read;
  }

  parseCsv(
    content: string,
    delimiter?: string,
    qualifier?: string,
    lineSeparator?: string,
    skipHeader?: boolean
  ): string[][] {
    const splitter = new RegExp('[' + (delimiter || ',;') + '](?=(?:(?:[^"]*"){2})*[^"]*$)', 'ug');
    return content
      .split(lineSeparator || /[\n\r]+/)
      .slice(skipHeader ? 1 : 0)
      .filter(line => line.length > 0)
      .map(line => line.split(splitter).map(refi => refi
        .replace(/(^")|("$)/g, '')
        .replace('""', '"')
        .trim()
      ));
    // .map(line => line.split(delimiter || ','));
  }

  removeBom(content: string) {
    if (content.charCodeAt(0) === 0xFEFF) {
      content = content.slice(1);
    }
    return content;
  }
}
