declare let jQuery: any;

/**
 * TimeGap class.
 * @param options Available options are:
 *      Date|number|string moment Date object, positive number meaning timestamp milliseconds,
 *        negative number meaning milliseconds ahead from now or string to be parsed by Date.parse().
 *      number interval=1000 Refreshing interval in milliseconds.
 *      boolean inverted=false By default formatted time is negative until the counter expires
 *        and positive after that. Set to true to invert this schema.
 *      boolean infinite=false Tells if stop the counter after it expires.
 *      function onInit()
 *      function onUpdate(number difference)
 *      function onExpire(number difference)
 *      boolean autoStart=true Tells it to start counting automatically (use .start() method alternatively).
 *
 * @constructor
 */
export class TimeGap {

  private config;

  private moment;
  private totalMilliseconds;
  private previousExpired;
  private days;
  private hours;
  private minutes;
  private seconds;
  private totalSeconds;
  private loop;
  private updateCallbacks = [];
  private expireCallbacks = [];
  private translations = {
    en: {
      days: 'days'
    },
    pl: {
      days: 'dni'
    }
  };

  constructor(options?: {}/*{
    // Date object, positive number meaning timestamp milliseconds, negative number meaning milliseconds
    // ahead from now or string to be parsed by Date.parse().
    moment?: Date|number|string,
    // Refreshing interval in milliseconds. Defaults to 1000 milliseconds.
    interval?: 1000,
    // By default formatted time is negative until the counter expires and positive after that.
    // Set to true to invert this schema. Defaults to false.
    inverted?: false,
    // Tells if stop the counter after it expires. Defaults to false.
    infinite?: false;
    onInit?: () => TimeGap,
    onUpdate?: (difference: number) => TimeGap,
    onExpire?: (difference: number) => TimeGap,
    // Tells it to start counting automatically (use .start() method alternatively). Defaults to true.
    autoStart?: true
  }*/) {
    this.config = {
      moment: null,
      interval: 1000,
      inverted: false,
      infinite: false,
      onInit: null,
      onUpdate: null,
      onExpire: null,
      autoStart: true,
      language:
        document.getElementsByTagName('html')[0].getAttribute('lang')
        || window.navigator.language
        || window.navigator['userLanguage']
        || 'en'
    };

    jQuery.extend(true, this.config, options);
    if (this.config.onUpdate) {
      this.onUpdate(this.config.onUpdate);
    }
    if (this.config.onExpire) {
      this.onExpire(this.config.onExpire);
    }
    if (this.config.moment === undefined || this.config.moment === null || this.config.moment === false) {
      this.set(new Date());
    } else {
      this.set(this.config.moment);
    }
    if (this.config.onInit) {
      this.config.onInit.call(this);
    }
    if (this.config.autoStart) {
      this.start();
    }
  }

  /**
   * Starts counting from now or options.moment.
   * @param options
   * @returns {TimeGap}
   */
  static count(options): TimeGap {
    if (options) {
      if (options.inverted === undefined) {
        options.inverted = true;
      }
      if (options.infinite === undefined) {
        options.infinite = true;
      }
    }
    return new TimeGap(options);
  }

  /**
   * Starts counting down until moment.
   * @param moment
   * @param options
   * @returns {TimeGap}
   */
  static countdown(moment, options): TimeGap {
    // console.log(options)
    if (options && options.moment === undefined) {
      options.moment = moment;
    }
    return new TimeGap(options);
  }

  onUpdate(callback): this {
    this.updateCallbacks.push(callback);
    return this;
  }

  onExpire(callback): this {
    this.expireCallbacks.push(callback);
    return this;
  }

  /**
   * @see moment option
   * @param {Date|number|string} newMoment
   * @returns {TimeGap}
   */
  set(newMoment): this {
    if (!(newMoment instanceof Date)) {
      if (newMoment >= 0) {
        newMoment = new Date(newMoment);
      } else if (newMoment < 0) {
        newMoment = new Date(Date.now() + Math.abs(newMoment));
      } else {
        newMoment = Date.parse(newMoment);
      }
    }
    if (this.loop) {
      this.stop();
      this.moment = newMoment;
      this.start();
    } else {
      this.moment = newMoment;
      this.refresh();
    }
    return this;
  }

  /**
   * @returns number
   */
  get(): number {
    return this.totalMilliseconds;
  }

  updatePieces(): void {
    this.totalSeconds = Math.ceil(this.totalMilliseconds / 1000);
    this.seconds = Math.abs(this.totalSeconds) % 60;
    this.minutes = Math.floor(Math.abs(this.totalSeconds) / 60) % 60;
    this.hours = Math.floor(Math.abs(this.totalSeconds) / 60 / 60) % 24;
    this.days = Math.floor(Math.abs(this.totalSeconds) / 60 / 60 / 24);
  }

  format(): string {
    this.updatePieces();
    return (((this.totalSeconds < 0 && !this.config.inverted) || (this.totalSeconds > 0 && this.config.inverted)) ? '-' : '') +
      (this.days > 0 ?
        ((this.days * 24 + this.hours) + ':')
        : (this.hours ? ((this.hours >= 10 ? this.hours : '0' + this.hours) + ':') : '')) +
      (this.minutes >= 10 ? this.minutes : '0' + this.minutes) + ':' +
      (this.seconds >= 10 ? this.seconds : '0' + this.seconds);
  }

  humanized(): string {
    this.updatePieces();
    return (((this.totalSeconds < 0 && !this.config.inverted) || (this.totalSeconds > 0 && this.config.inverted)) ? '-' : '') +
      (this.days >= 2 ?
          (this.days + ' ' + this.translations[this.config.language].days)
          :
          (this.hours >= 10 ? (this.days * 24 + this.hours) : '0' + this.hours) + ':'
          + (this.minutes >= 10 ? this.minutes : '0' + this.minutes) + ':'
          + (this.seconds >= 10 ? this.seconds : '0' + this.seconds)
      );
  }

  isExpired(): boolean {
    return this.totalMilliseconds <= 0;
  }

  getConfig(): any {
    return jQuery.extend(true, {}, this.config);
  }

  refresh(): this {
    let i;
    this.totalMilliseconds = this.moment.getTime() - Date.now();
    if (this.totalMilliseconds <= 0 && !this.config.infinite && !this.previousExpired) {
      this.stop();
      this.totalMilliseconds = 0;
    }
    for (i in this.updateCallbacks) {
      if (this.updateCallbacks.hasOwnProperty(i)) {
        this.updateCallbacks[i].call(this, this.totalMilliseconds);
      }
    }
    if (this.totalMilliseconds <= 0 && !this.previousExpired) {
      this.previousExpired = true;
      for (i in this.expireCallbacks) {
        if (this.expireCallbacks.hasOwnProperty(i)) {
          this.expireCallbacks[i].call(this, this.totalMilliseconds);
        }
      }
    }
    return this;
  }

  isCounting(): boolean {
    return !!this.loop;
  }

  start(interval?: number): this {
    if (this.isCounting()) {
      this.stop();
    }
    this.refresh();
    if (this.totalMilliseconds > 0 || this.config.infinite) {
      this.loop = setInterval(() => this.refresh(), interval === undefined ? this.config.interval : interval);
    }
    return this;
  }

  stop(): this {
    clearInterval(this.loop);
    return this;
  }
}
