import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'normalizeLocalISOString'
})
export class NormalizeLocalISOStringPipe implements PipeTransform {

  transform(timestampCandidate: string | number, args?: any): string {
    if (typeof timestampCandidate === 'string') {
      return timestampCandidate.replace(/^([0-9]{4}(\-[0-9]{2}){2})\s(([0-9]{2}\:){2}[0-9]{2}\.[0-9]+)/, '$1T$3');
    } else {
      return moment(timestampCandidate).format('YYYY-MM-DD\THH:mm:ss.SSS');
    }
  }
}
