import { Injectable } from '@angular/core';
import { TagReading } from './tag-reading';
import { FileReaderService } from '../../app-commons/file-reader/file-reader.service';
import { TagReadingsService } from './tag-readings.service';
import { ToUniqueTagReadingIdPipe } from './to-unique-tag-reading-id.pipe';

@Injectable({
  providedIn: 'root'
})
export class RfidSolutionsTagArriveReadingsService {

  readonly SEPARATOR = 'event.tag.arrive ';

  constructor(
    private fileReaderService: FileReaderService,
    private readonly toUniqueTagReadingIdPipe: ToUniqueTagReadingIdPipe
  ) {
  }

  parseToTagReadings(rawContent: string): TagReading[] {
    // event.tag.arrive tag_id=0x10004D110056F211, first=2023-03-25T09:02:47.010, antenna=2
    return this.fileReaderService
      .parseCsv(
        rawContent.replace(new RegExp(this.SEPARATOR, 'gu'), '\n'),
        ',',
        undefined,
        undefined,
        true
      ).map(entry => entry.map(param => param.replace(/^.*=/u, '')))
      .map(entry => {
        const tagCode = this.tagIdToTagCode(entry[0]);
        const timestamp = new Date(entry[1]).getTime();
        return {
          _id: this.toUniqueTagReadingIdPipe.transform(timestamp, tagCode),
          tagCode,
          timestamp
        };
      });
  }

  tagIdToTagCode(tagId: string) {
    return tagId.replace(/(^0x1000)|(.{4}$)|(\s+)/g, '');
  }

  isValid(rawContent) {
    return rawContent.indexOf(this.SEPARATOR) >= 0;
  }
}
