import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GateReadingsService } from '../../gepard/gate-readings/gate-readings.service';
import { combineLatest, forkJoin, Subscription, throwError } from 'rxjs';
import { switchMap, mergeAll, scan, tap, map, shareReplay } from 'rxjs/operators';
import { DriversService } from '../../gepard/drivers/drivers.service';
import { RacesService } from '../races/races.service';
import { Race } from '../races/race';
import { StandingsService } from '../standings/standings.service';
import { StandingsFilterPipe } from '../standings/standings-filter.pipe';
import { ResultsService } from '../results/results.service';
import { Ranking } from '../ranking';
import { RankingsService } from '../rankings.service';
import { RemoteResultsService } from '../results/remote-results.service';
import { Standing } from '../standing';
import { Observable } from 'rxjs';
import { Result } from '../results/result/result';
import { Driver } from '../../gepard/drivers/driver';
import { ResultsSyncEvent } from '../results/results-sync-event';
import * as moment from 'moment';
import { SpreadsheetService } from '../results/spreadsheet.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'mc-race-results',
  templateUrl: './race-results.component.html',
  styleUrls: ['./race-results.component.scss']
})
export class RaceResultsComponent implements OnInit, OnDestroy {

  race: Race;
  rankings: Ranking[] = [];

  resultsFeedSubscription: Subscription;
  resolveSubscription: Subscription;
  initSubscription: Subscription;

  syncProgress$: Observable<{ display: boolean, percent: number, lastLapTime: moment.Moment, lapsCount: number }> = this.remoteResultsService.sync$.pipe(
    map((event: ResultsSyncEvent) => {
      if (event.total > 0) {
        const percent = (event.total == 0 || event.total == event.processed) ? 100 : Math.floor(event.processed / event.total * 100);
        return {
          display: event.pending,
          percent: percent,
          lastLapTime: moment(event.createdAt),
          lapsCount: event.processed
        };
      } else {
        return undefined;
      }
    }),
    tap(() => this.ref.detectChanges())
  );

  syncProgress: { display: boolean, percent: number, lastLapTime: moment.Moment, lapsCount: number };
  syncProgressSubscription: Subscription;

  loading = true;

  constructor(
    private route: ActivatedRoute,
    private driversService: DriversService,
    private gateReadingsService: GateReadingsService,
    private racesService: RacesService,
    private standingsService: StandingsService,
    private standingsFilter: StandingsFilterPipe,
    private resultsService: ResultsService,
    private rankingsService: RankingsService,
    private remoteResultsService: RemoteResultsService,
    private spreadsheetService: SpreadsheetService,
    private ref: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.initSubscription = forkJoin([
      this.rankingsService.requestRankings(),
      this.racesService.requestRaces()
    ]).pipe(
      switchMap(([rankings, races]) => {
        this.rankings = rankings;
        this.racesService.races = races;
        return this.route.params;
      })
    ).subscribe(params => this.resolve(+params.raceId));
  }

  ngOnDestroy() {
    if (this.initSubscription) {
      this.initSubscription.unsubscribe();
    }
    if (this.resolveSubscription) {
      this.resolveSubscription.unsubscribe();
    }
    if (this.resultsFeedSubscription) {
      this.resultsFeedSubscription.unsubscribe();
    }
    this.remoteResultsService.disconnect();
    this.remoteResultsService.syncOff();
    if (this.syncProgressSubscription) {
      this.syncProgressSubscription.unsubscribe();
    }
  }

  resolve(raceId: number) {
    this.loading = true;
    this.race = this.racesService.findById(raceId);
    this.resultsService.clearState();

    if (this.resultsFeedSubscription) {
      this.resultsFeedSubscription.unsubscribe();
      this.remoteResultsService.syncOff();
    }

    if (this.resolveSubscription) {
      this.resolveSubscription.unsubscribe();
    }

    this.resolveSubscription = combineLatest([
      this.driversService.requestDrivers(this.race/*, {pollingInterval: 5000}*/),
      // this.loadGateReadings(this.race.gateReadingsCsvFiles),
      this.loadResults(this.race).pipe(
        tap(results => this.resultsService.broadcastChanges(results)),
        shareReplay()
      )
    ]).subscribe(([drivers, results]: [Driver[], Result[]]) => {
      this.driversService.drivers = drivers;
      // const standings = this.standingsService.fromGateReadings(gateReadings);

      if (this.resultsFeedSubscription) {
        this.resultsFeedSubscription.unsubscribe();
      }
      this.resultsFeedSubscription = this.resultsService.results$.subscribe(changed => {
        this.resultsService.mergeToState(changed);
        // console.log('new results', this.standingsService.fromResults(this.resultsService.results));
        // TODO Recalculate only changed standings...
        this.recalculateRankings(this.standingsService.fromResults(this.resultsService.results));
        // console.log('Received results ', changed.length);
      });
      this.loading = false;
    });
    this.syncProgressSubscription = this.syncProgress$.subscribe(syncProgress => this.syncProgress = syncProgress);
  }

  recalculateRankings(standings: Standing[]) {
    this.rankings.forEach(ranking => {
      ranking.standings = this.standingsFilter
        .transform(standings, ranking.standingsConfig)
        .map(standing => this.standingsService.getRecalculated(standing, this.race.resultsConfig));
    });
  }

  loadGateReadings(mockPaths: string[]) {
    return forkJoin(mockPaths.map(mockPath => this.gateReadingsService.requestGateReadings(mockPath))).pipe(
      mergeAll(),
      scan((a, b) => GateReadingsService.merge(a, b)),
    );
  }

  loadResults(race: Race): Observable<Result[]> {
    if ('resultsJsonFile' in race) {
      return this.resultsService.requestResults(race.resultsJsonFile);
    } else if ('resultsCouchDbName' in race) {
      return this.remoteResultsService.connect(race.resultsCouchDbName).pipe(
        tap(() => this.remoteResultsService.syncOn(true, environment.isAdmin)),
        switchMap(() => this.remoteResultsService.findAll())
      );
    }
    return throwError('No results source in race object.');
  }
}
