import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Result } from './result';
import { ResultsMetaService } from '../results-meta/results-meta.service';

@Component({
  selector: 'mc-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent {

  @Input() result: Result;
  @Output() changed = new EventEmitter<Result>();

  @HostListener('mouseover') hover() {
    this.resultsMetaService.hover(this.result);
  }

  @HostListener('mouseout') unhover() {
    this.resultsMetaService.unhover(this.result);
  }

  @HostListener('click') toggleVerification() {
    // if (this.result.verification === null) {
    //   this.result.verification = false;
    // } else if (this.result.verification === false) {
    //   this.result.verification = true;
    // } else if (this.result.verification === true) {
    //   this.result.verification = null;
    // }
    // this.unhover();
    // this.changed.emit(this.result);
  }

  constructor(private resultsMetaService: ResultsMetaService) {
  }

  isHovered() {
    return this.resultsMetaService.isHovered(this.result);
  }

  getDuplicates() {
    return this.resultsMetaService.get(this.result).duplicates;
  }
}
