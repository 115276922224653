import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface McApiModel {
  id: number;
}

export interface McApiModelResponse {
  model: McApiModel;
}

export interface McApiInvalidModelResponse {
  model: McApiModel;
}

export interface McApiCollectionResponse {
  collection: McApiModel[];
}

export interface McApiErrorResponse {
  error: string;
  project: string;
  url: string;
  time: string;
  referer: string;
  backtrace: any[];
}

const defaultHttpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable()
export class McApiConnectorService implements OnInit {

  readonly urlPrefix = 'http://mc-api/api';

  private connection$ = new Subject();

  // private response$: Observable<McApiModelResponse | McApiInvalidModelResponse | McApiCollectionResponse | McApiErrorResponse>;

  constructor(private http: HttpClient) {
  }

  ngOnInit() {
    // this.response$.pipe(
    //   map(a => {
    //     console.log(a);
    //     return a;
    //   })
    // );
  }

  request(method: string, url: string, options: any = {}) {
    const httpOptions = { ...defaultHttpOptions, ...options };
    httpOptions.body = JSON.stringify(options.body);
    httpOptions.responseType = 'json';
    return this.http.request(method, this.urlPrefix + url, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  get(url: string, options: any = {}) {
    return this.request('get', url, options);
  }

  post(url: string, body?: any, options: any = {}) {
    if (body) {
      options.body = body;
    }
    return this.request('post', url, options);
  }

  put(url: string, body?: any, options: any = {}) {
    if (body) {
      options.body = body;
    }
    return this.request('put', url, options);
  }

  delete(url: string, options: any = {}) {
    // return this.request('delete', url, options);
    return this.http.delete(this.urlPrefix + url, options);
  }

  getCollection(relativeUrl: string, options: any = {}) {
    return this.request('get', relativeUrl, options)
      .pipe(
        map((collectionResponse: any) => collectionResponse.collection),
      );
  }

  getModel(relativeUrl: string, options: any = {}) {
    return this.request('get', relativeUrl, options)
      .pipe(
        map((modelResponse: any) => modelResponse.model)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.log('McApi error event: ' + error.error.message);
      return 'McApi error event: ' + error.error.message;
    } else {
      console.log('McApi http error: ' + error.error);
      return 'McApi http error: ' + error.error;
    }
  }
}
