import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Standing } from '../standing';
import { ResultsConfig } from '../results/results-config/results-config';
import { StandingsConfig } from '../standings/standings-config';
import { StandingsFilterPipe } from '../standings/standings-filter.pipe';
import { StandingsService } from '../standings/standings.service';
import { Race } from '../races/race';

@Component({
  selector: 'mc-race-ranking-standings',
  templateUrl: './race-ranking-standings.component.html',
  styleUrls: ['./race-ranking-standings.component.scss']
})
export class RaceRankingStandingsComponent implements OnInit, OnChanges {

  @Input() race: Race;
  @Input() standings: Standing[] = [];
  @Input() resultsConfig: ResultsConfig = {};
  @Input() standingsConfig: StandingsConfig = {};

  filteredStandings: Standing[] = [];


  constructor(
    private standingsFilter: StandingsFilterPipe,
    private standingsService: StandingsService
  ) {}

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.standings && changes.standings.currentValue) {
      // console.log('standings changed', changes.standings.currentValue);
      this.onStandingsConfigUpdate(changes.standings.currentValue);
    }
    if (changes.standingsConfig && changes.standingsConfig.currentValue) {
      // console.log('standingsConfig changed', changes.standingsConfig.currentValue);
      this.onStandingsConfigUpdate(changes.standingsConfig.currentValue);
    }
    if (changes.resultsConfig && changes.resultsConfig.currentValue) {
      // console.log('resultsConfig changed', changes.resultsConfig.currentValue);
      this.onResultsConfigUpdate(changes.resultsConfig.currentValue);
    }
  }

  onStandingsConfigUpdate(standingsConfig: StandingsConfig): void {
    this.filteredStandings = this.standingsFilter.transform(this.standings, standingsConfig);
  }

  onResultsConfigUpdate(resultsConfig: ResultsConfig): void {
    this.filteredStandings = this.filteredStandings.map(standing => {
      return this.standingsService.getRecalculated(standing, resultsConfig);
    });
  }
}
