import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DatePipe, DecimalPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { McApiClientModule } from './mc-api-client/mc-api-client.module';
import { GepardModule } from './gepard/gepard.module';
import { RankingModule } from './ranking/ranking.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RacesService } from './ranking/races/races.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    RouterModule,
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    McApiClientModule,
    PopoverModule.forRoot(),
    GepardModule,
    RankingModule,
    NgbModule
  ],
  exports: [
    RouterModule
  ],
  declarations: [
    AppComponent,
    DashboardComponent
  ],
  providers: [
    DecimalPipe,
    DatePipe,
    RacesService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
