import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { PopoverConfig } from 'ngx-bootstrap/popover';
import { RacesService } from './ranking/races/races.service';
import { Race } from './ranking/races/race';
import { Observable } from 'rxjs';

export function getPopoverConfig(): PopoverConfig {
  return Object.assign(new PopoverConfig(), {
    placement: 'top',
    triggers: 'mouseenter:mouseleave',
    outsideClick: true
  });
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [{ provide: PopoverConfig, useFactory: getPopoverConfig }]
})
export class AppComponent implements OnInit {
  title = 'AC LapTimer';

  races$: Observable<Race[]> = this.racesService.requestRaces();

  constructor(
    private racesService: RacesService
  ) {
  }

  ngOnInit() {
    moment.locale('pl');
  }
}
