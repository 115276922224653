import { Pipe, PipeTransform } from '@angular/core';
import { ResultsConfig } from '../results-config/results-config';

@Pipe({
  name: 'laptimeRangeFilter'
})
export class LaptimeRangeFilterPipe implements PipeTransform {

  transform(microtime: number, resultsConfig: ResultsConfig): boolean {
    return (resultsConfig.minimumLapSeconds === undefined || resultsConfig.minimumLapSeconds * 1000000 <= microtime)
      && (resultsConfig.maximumLapSeconds === undefined || microtime <= resultsConfig.maximumLapSeconds * 1000000);
  }

}
